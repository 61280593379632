import styled, { css } from "styled-components";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

export const DialogModal = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 10px; 
        padding: 20px; 
        gap: 20px; 
        width: 30%;
        min-width: 350px;

        ${(props) => css`
            ${props.sx}  
        `}
    }

    a{
        color: black;
    }
`

export const ModalTitle = styled(DialogTitle)`
    padding: 0 !important;
`

export const ModalContent = styled(DialogContent)`
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .formatItem{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .ol-container{
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title-medium{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`

export const ModalActions = styled(DialogActions)`
    justify-content: center!important;
    padding: 0!important;
`

