import React, {
    useState, useEffect
} from 'react';
import { StyledTableCellHead, StyledTableCell, StyledTableRow, StyledTableContainer } from '../DefaultTable/DefaultTable';
import Status from '../Status/Status';
import { Search, TodayRounded, FilterAltOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    IconButton, TableBody,
    TableHead,
    TableRow,
} from '@mui/material';
import InjectedTextField from '../InjectedTextField/InjectedTextField';

const JobTable = ({ jobs }) => {
    const navigate = useNavigate();
    const [filteredJobs, setFilteredJobs] = useState([...jobs]);
    const [isActiveSearch, setIsActiveSearch] = useState(false);

    useEffect(() => {
        setFilteredJobs([...jobs])
    }, [jobs])

    const handleOpenJob = (id) => {
        navigate(id.toString());
    }

    const filterCompanyList = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredList = jobs.filter((item) =>
            item.name.toLowerCase().includes(searchTerm))

        if (e.target.value === '' || e.target.value === undefined) {
            setFilteredJobs(jobs);
        } else {
            setFilteredJobs(filteredList)
        }
    }

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important', width: '30%' }}>
                            <div className='flex-row'>

                                {!isActiveSearch && <p className={"title-medium"}>Jobs</p>}
                                {isActiveSearch && <InjectedTextField
                                    isActiveSearch={isActiveSearch}
                                    sx={{ width: '80%' }}
                                    onChange={filterCompanyList}
                                    onBlur={() => { setIsActiveSearch(false) }}
                                />}

                                <IconButton onClick={() => { setIsActiveSearch(true) }} sx={{ color: '#fff' }}>
                                    <Search fontSize='small' /></IconButton>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important' }} className={"title-medium"}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Data de criação</p>
                                <IconButton sx={{ color: '#fff' }}>
                                    <TodayRounded fontSize='small' /></IconButton>
                            </div>
                        </StyledTableCellHead>
                        <StyledTableCellHead sx={{ padding: '5px 20px !important' }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Status</p>
                                <IconButton sx={{ color: '#fff' }}>
                                    <FilterAltOutlined fontSize='small' /></IconButton>
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredJobs.map((job) => (
                        <StyledTableRow onClick={(e) => { handleOpenJob(job.id) }} key={job.name}>
                            <StyledTableCell className='body-large'>{job.name}</StyledTableCell>
                            <StyledTableCell className='body-large'>{job.createdAt}</StyledTableCell>
                            <StyledTableCell className='body-large'>
                                <Status status={job.status}>{job.status}</Status>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default JobTable;
