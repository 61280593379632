import React, { useState } from "react";
import { Container, AddButton } from "./style"
import TermRow from "../TermRow/TermRow"
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddTermModal from "../Modal/TermModal/AddTermModal";

const BudgetTerms = ({ terms, budgetVersionId }) => {
    const [openAddModal, setOpenAddModal] = useState(false);

    const handleAddNewTerm = (e) => {
        setOpenAddModal(true);
    }

    return (
        <Container>
            <div className="terms-header">
                <p className="title-small">Legal / Cláusulas</p>
            </div>

            <div className="terms-content">
                {terms && terms.map((t, i) => {
                    return <TermRow budgetVersionId={budgetVersionId} id={t.id} index={i} key={i} description={t.description} text={t.text} />
                })}

                <AddButton onClick={handleAddNewTerm}>
                    <AddCircleOutlineRoundedIcon fontSize="small" />
                    <u className="title-small">Adicionar Cláusula</u>
                </AddButton>
            </div>

            {openAddModal &&
                <AddTermModal
                    budgetVersionId={budgetVersionId}
                    actualTermId={"0"}
                    open={openAddModal}
                    handleClose={() => { setOpenAddModal(false) }}
                />}
        </Container>
    )
}

export default BudgetTerms;