import React, { useContext, useEffect, useState } from "react";
import { Container, PageHeader } from "./style";
import JobTable from "../../components/JobTable/JobTable";
import { useParams } from 'react-router-dom';
import { EditOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { CompanyContext } from "../../contexts/CompanyContext";
import { checkToken } from "../../utils/methods";
import { CircularProgress } from "@mui/material";

const JobsListPage = () => {
    const [jobs, setJobs] = useState([]);
    const { getIdFromName, companyList } = useContext(CompanyContext);
    const { companyName } = useParams();
    const [requestStatus, setRequestStatus] = useState("INITIAL")

    useEffect(() => {
        checkToken();
        if (companyList.length > 0) {
            var jobsFormCompany = '/Job/GetJobsByCompany/';
            var formData = { companyId: getIdFromName(companyName) }

            api.post(jobsFormCompany, formData).then((response) => {
                return response.data;
            }).then((data) => {
                setRequestStatus("LOADED")
                setJobs(data)
            }).catch((error) => {
                if (error.status === 404) {
                    setRequestStatus("NOT FOUND")
                }
                if (error.code === "ERR_NETWORK") {
                    window.location.href = "/"
                }
            })
        }
    }, [companyList])

    return (
        <Container>
            <PageHeader>
                <Link to={"/empresas"} className="title-small">
                    <ArrowBack /> Voltar
                </Link>
            </PageHeader>

            <PageHeader>
                <div className="company-title">
                    <p className="headline-small">{companyName}</p>
                    <IconButton>
                        <EditOutlined sx={{ color: "#000" }} />
                    </IconButton>
                </div>
                {/* <CustomButton variant={"contained"}>
                    Gerenciar acessos
                </CustomButton> */}
            </PageHeader>

            {requestStatus === "LOADED" &&
                <JobTable jobs={jobs} companyName={companyName} />}
            {requestStatus === "NOT FOUND" && <p className="title-medium">Ah, ainda não existem jobs.</p>}
            {requestStatus === "INITIAL" && <div className="loading-container">
                <CircularProgress color="inherit" />
                <p className="title-medium">Carregando...</p>
            </div>}
        </Container>
    )
}

export default JobsListPage;