import colors from "../../utils/colors.json"
import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1.5px solid  ${colors.light_gray};
    border-radius: 10px;
    overflow: hidden;
    width: 100%;

    .summary-header{
        background-color: ${colors.gray};
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 10px;
        font-weight: 500;
        color: ${colors.white};

        .title-small{
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    
    .summary-content{
        display: flex;
        width: 100%;
        padding: 10px;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;

        ul{
            padding-left: 15px;
        }
    }

    .format-list{
        border-right: 1px solid ${colors.carbon};
        padding-right: 20px;
        margin-right: 20px;

        .label-medium{
            font-weight: 500 !important;
            margin-bottom: 10px;
            font-size: 12px;
            white-space: nowrap;
        }
    }

    .format-list:nth-last-child(1){
        border-right: none;
    }
` 