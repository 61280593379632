import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import BudgetHeader from "../../components/BudgetHeader/BudgetHeader";
import { Link, useParams } from "react-router-dom"
import BudgetBody from "../../components/BudgetBody/BudgetBody";
import DeleteBudgetAlertModal from "../../components/Modal/DeleteBudgetAlertModal/DeleteBudgetAlertModal";
import api from "../../services/api";
import { checkToken } from "../../utils/methods";
import { Skeleton } from "@mui/material";

const BudgetPage = () => {
    const [openModalDeleteBudget, setOpenModalDeleteBudget] = useState(false);
    const [budget, setBudget] = useState(null);
    const { id } = useParams()

    const handleOpenDeleteModal = () => {
        setOpenModalDeleteBudget(true);
    }

    useEffect(() => {
        checkToken();
        var getBudgetURL = '/Job/GetJobWithBudgetStatus'
        var formData = { jobId: id }

        api.post(getBudgetURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setBudget(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    return (<>
        {budget ?
            <Container>
                <PageHeader>
                    <Link to={"/budgets"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>

                    <Link className="title-small" onClick={handleOpenDeleteModal}>
                        Deletar Budget
                    </Link>
                </PageHeader>
                <BudgetHeader budget={budget} />
                <BudgetBody budget={budget} />
                <DeleteBudgetAlertModal
                    open={openModalDeleteBudget}
                    handleClose={() => { setOpenModalDeleteBudget(false) }}
                />
            </Container> : <Container>
                <PageHeader>
                    <Link to={"/budgets"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>

                    <Link className="title-small" onClick={handleOpenDeleteModal}>
                        Deletar Budget
                    </Link>
                </PageHeader>
                <Skeleton variant="rounded" width={"100%"} height={"10%"} />
                <Skeleton variant="rounded" width={"100%"} height={"15%"} />
            </Container>}
    </>
    )
}

export default BudgetPage;